import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import Footer from "../components/footer";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Seo from "../components/seo";
import { translations } from "../translations";
import type { Translation } from "../types/main";

const t: Translation = {
	language: "en",
	translations: translations.en,
	singleLanguage: true,
};

export default function NotFoundPage() {
	return (
		<Layout>
			<Seo title="Flowby - Cut your next line" />
			<Navbar t={t} />
			<Container maxW="5xl" py={{ base: 12, md: 24 }}>
				<Stack as={Box} spacing={{ base: 5, md: 8 }}>
					<Heading as="h1" fontSize="3xl">
						Sorry! This page does not exist.
					</Heading>
					<Text fontSize="xl" color={"gray.700"}>
						We messed up and you ended up here. Please contact us at
						hello@flowby.io if you need help or have questions.
					</Text>
				</Stack>
			</Container>
			<Container maxW="8xl" py={12}>
				<Footer t={t} />
			</Container>
		</Layout>
	);
}
